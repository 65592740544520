import React, {useEffect} from "react";

export const useOnClickOutside = (
  boundaryElement: React.MutableRefObject<HTMLElement | null>,
  onClickOutside: (e: MouseEvent) => unknown,
) => {
  useEffect(() => {
    const eventHandler = (e: MouseEvent) => {
      if (
        boundaryElement.current &&
        e.target instanceof Node &&
        !boundaryElement.current.contains(e.target)
      ) {
        onClickOutside(e);
      }
    };

    document.addEventListener("mousedown", eventHandler);

    return () => {
      document.removeEventListener("mousedown", eventHandler);
    };
  }, [boundaryElement, onClickOutside]);
};
