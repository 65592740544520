import React from "react";

import {s3ImagesRootUrl} from "./components/_common/_constants";
import {useTypedSelector} from "./store";

export const useS3ImgSrc = (id: string, density = 3): string => {
  const {imgExt} = useTypedSelector(({config}) => config);
  return `${s3ImagesRootUrl}/${id}/${density}x.${imgExt}`;
};

const S3ImageComponent: React.FC<{
  id: string;
  density?: number;
  className?: string;
  alt: string;
}> = ({id, density, className, alt, ...props}) => (
  <img src={useS3ImgSrc(id, density)} {...{className, alt}} {...props} />
);
S3ImageComponent.displayName = "S3ImageComponent";

export const S3Image = React.memo(S3ImageComponent);

export const s3ImageSource = (id: string, ext = "jpg", density = 3): string =>
  `${s3ImagesRootUrl}/${id}/${density}x.${ext}`;
