import moment from "moment-timezone";

import {laTimezone} from "../components/_common/_constants";

const getTimezone = (): string => {
  try {
    return moment.tz.guess();
  } catch (err) {
    return laTimezone; // could also be something like moment.tz.names()[0];
  }
};

export default getTimezone;
