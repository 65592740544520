/**
 * Safe wrapper around mediaQueryList.addEventListener(), because it's not supported by
 * Safari versions < 14.
 */
export function addListenerSafe(
  mql: MediaQueryList,
  listener: (this: MediaQueryList, ev: MediaQueryListEvent) => unknown,
) {
  if (mql.addEventListener) {
    mql.addEventListener("change", listener);
  } else {
    mql.addListener(listener);
  }
}

/**
 * Safe wrapper around mediaQueryList.removeEventListener(), because it's not supported by
 * Safari versions < 14.
 */
export function removeListenerSafe(
  mql: MediaQueryList,
  listener: (this: MediaQueryList, ev: MediaQueryListEvent) => unknown,
) {
  if (mql.removeEventListener) {
    mql.removeEventListener("change", listener);
  } else {
    mql.removeListener(listener);
  }
}
