import React from "react";

import {inputStyles} from "../utils/commonStyles";

type LabelProps = {
  label?: string;
  id?: string;
  htmlFor?: string;
  isLabelSmall: boolean;
};

const Label = ({label, id, htmlFor, isLabelSmall}: LabelProps) => {
  if (!label) return null;

  return (
    <label
      id={id}
      htmlFor={htmlFor}
      className={`${inputStyles.label} ${
        isLabelSmall ? "top-1 sm:top-2 scale-[.7]" : "top-3 sm:top-4 scale-100"
      }`}
    >
      {label}
    </label>
  );
};

export default React.memo(Label);
