import memoizee from "memoizee";
import {LinkProps} from "next/link";
import React from "react";

import {ValueOf} from "../../types";
import {noOp} from "../../utils/noOp";
import {shouldAllowReferrer} from "../../utils/shouldAllowReferrer";
import LinkOrButton from "./LinkOrButton";

export type ButtonVariant = ValueOf<typeof ButtonVariant>;
export const ButtonVariant = {
  DESTROY: "destroy",
  GHOST: "ghost",
  GREEN: "green",
  OUTLINE: "outline",
  FILL: "fill",
  WHITE: "white",
  SELECTED_FILL: "selectedFill",
  NAKED: "naked",
} as const;

type ButtonSize = ValueOf<typeof ButtonSize>;
export const ButtonSize = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
  JB: "jb",
} as const;

type ButtonShape = ValueOf<typeof ButtonShape>;
export const ButtonShape = {
  ROUND: "round",
  CIRCLE: "circle",
} as const;

/**
 * Note: 'hover:' and 'disabled:hover:' are required because 'a' tags do not match 'enabled:'
 * Latest buttons guide: https://www.figma.com/file/PKqE0iYfxXHC6zZ0Rzq5Hq/Buttons?node-id=0%3A1
 */
const styles = {
  focus: "outline-hidden outline-4 outline-offset-0 outline-brightBlue focus-visible:outline",
  base: "border border-solid box-border transition-colors transition-opacity flex justify-center items-center",
  baseText: "whitespace-nowrap text-center font-i",
  baseInteraction: "cursor-pointer disabled:cursor-default disabled:opacity-70",

  destroy:
    "bg-error-800 border-error-800 text-white hover:text-white hover:bg-error-600 hover:border-error-600 disabled:hover:bg-error-800 disabled:hover:border-error-800",
  ghost:
    "border-gray100 bg-gray800/10 text-gray800 hover:text-gray800 hover:bg-gray800/20 disabled:hover:bg-gray800/10 hover:border-gray50 disabled:hover:border-gray100",
  green:
    "border-darkGreen bg-darkGreen text-white hover:text-white hover:bg-green disabled:hover:bg-darkGreen hover:border-green disabled:hover:border-darkGreen",
  outline:
    "border-gray800 text-gray800 hover:bg-gray800 hover:text-white disabled:hover:bg-transparent disabled:hover:text-gray800",
  fill: "border-gray800 bg-gray800 text-white disabled:hover:text-white hover:bg-white hover:text-gray800 disabled:hover:bg-gray800",
  white:
    "border-white bg-white text-gray800 hover:bg-gray800 hover:border-gray800 hover:text-white disabled:bg-gray800 disabled:text-white disabled:border-gray800 disabled:hover:border-gray800",
  selectedFill: "border-gray800 bg-gray800 text-white hover:text-white cursor-default",
  naked: "bg-transparent border-transparent text-gray800 hover:text-gray800",

  xs: "text-sm leading-none font-semibold px-3.5 py-[7px]",
  sm: "text-sm leading-none font-semibold px-[18px] py-[13px]",
  md: "text-base leading-none font-bold px-5 py-[17px]",
  lg: "text-base leading-none font-bold px-6 py-[21px]",
  jb: "text-xl leading-none font-bold p-[25px]",

  round: "rounded-4xl",
  circle: "rounded-full p-2 shrink-0 grow-0 leading-none flex items-center justify-center",
} as const;

const CircleSize = {
  xs: "h-8 w-8 text-sm",
  sm: "h-8 w-8 text-sm",
  md: "h-10 w-10 text-lg",
  lg: "h-10 w-10 text-lg",
  jb: "h-10 w-10 text-lg",
} as const;

const getCircleSize = (size: ButtonSize, shape: ButtonShape) =>
  shape === ButtonShape.CIRCLE ? CircleSize[size] : styles[size];

export const getButtonStyle = memoizee(
  (
    variant: ButtonVariant = ButtonVariant.FILL,
    size: ButtonSize = ButtonSize.MD,
    shape: ButtonShape = ButtonShape.ROUND,
    callerStyle: string,
  ): string =>
    [
      styles.focus,
      styles.base,
      styles.baseText,
      styles.baseInteraction,
      styles[variant],
      getCircleSize(size, shape),
      styles[shape],
      callerStyle,
    ].join(" "),
  {
    normalizer: JSON.stringify,
  },
);

const getRelAttribute = (href: LinkProps["href"] | null | undefined, newTab?: boolean) =>
  newTab && href ? (shouldAllowReferrer(href) ? "noopener" : "noopener noreferrer") : undefined;

const Button = ({
  variant = ButtonVariant.FILL,
  size = ButtonSize.MD,
  shape = ButtonShape.ROUND,
  children,
  onClick,
  href,
  newTab,
  disabled = false,
  className = "",
  ariaLabel,
  ...props
}: {
  variant?: ButtonVariant;
  size?: ButtonSize;
  shape?: ButtonShape;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler;
  href?: LinkProps["href"] | null;
  newTab?: boolean;
  disabled?: boolean;
  className?: string;
  ariaLabel?: string;
  "data-cy"?: string;
}): React.ReactElement => (
  <LinkOrButton
    href={disabled ? undefined : href}
    target={newTab ? "_blank" : undefined}
    rel={getRelAttribute(href, newTab)}
    onClick={disabled ? noOp : onClick}
    className={getButtonStyle(variant, size, shape, className)}
    disabled={disabled}
    aria-label={ariaLabel}
    data-cy={props["data-cy"]}
  >
    {children}
  </LinkOrButton>
);

export default React.memo(Button);
