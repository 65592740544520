import {FOCUSABLE_ELEMENT_SELECTOR} from "./constants";

export const getFirstFocusableChild = (el: HTMLElement) => {
  const queried = el.querySelector(FOCUSABLE_ELEMENT_SELECTOR);

  return queried instanceof HTMLElement ? queried : null;
};

export const getLastFocusableChild = (el: HTMLElement) => {
  const focusableEles = Array.from(el.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENT_SELECTOR));
  return focusableEles[focusableEles.length - 1] || null;
};
