import {useEffect, useState} from "react";

import {getFirstFocusableChild, getLastFocusableChild} from "./utils";

export const useFocusScope = (shouldTrap: boolean) => {
  const [focusedFrom, setFocusedFrom] = useState<HTMLElement | null>(null);
  const [focusScope, setFocusScope] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const rememberPreviouslyFocusedEl = (e: FocusEvent) => {
      if (e.relatedTarget instanceof HTMLElement && !focusedFrom) {
        setFocusedFrom(e.relatedTarget);
      }
    };

    focusScope?.addEventListener("focusin", rememberPreviouslyFocusedEl);

    return () => {
      focusScope?.removeEventListener("focusin", rememberPreviouslyFocusedEl);
    };
  }, [focusedFrom, focusScope]);

  useEffect(() => {
    const firstFocusableChild = focusScope ? getFirstFocusableChild(focusScope) : null;
    const lastFocusableChild = focusScope ? getLastFocusableChild(focusScope) : null;

    if (focusScope && (!firstFocusableChild || !lastFocusableChild))
      throw Error("Focus scope does not contain focusable elements.");

    firstFocusableChild?.focus();

    const releaseFocus = () => {
      if (focusedFrom && !shouldTrap) {
        focusedFrom.focus();
        setFocusedFrom(null);
      }
    };

    const preventWalkingOut = (e: KeyboardEvent) => {
      if (
        e.key === "Tab" &&
        !e.shiftKey &&
        lastFocusableChild &&
        firstFocusableChild &&
        shouldTrap
      ) {
        e.preventDefault();
        firstFocusableChild?.focus();
      }
    };

    const preventBackingOut = (e: KeyboardEvent) => {
      if (
        e.key === "Tab" &&
        e.shiftKey &&
        lastFocusableChild &&
        firstFocusableChild &&
        shouldTrap
      ) {
        e.preventDefault();
        lastFocusableChild?.focus();
      }
    };

    focusScope?.addEventListener("focusout", releaseFocus);
    lastFocusableChild?.addEventListener("keydown", preventWalkingOut);
    firstFocusableChild?.addEventListener("keydown", preventBackingOut);

    return () => {
      focusScope?.removeEventListener("focusout", releaseFocus);
      lastFocusableChild?.removeEventListener("keydown", preventWalkingOut);
      firstFocusableChild?.removeEventListener("keydown", preventBackingOut);
    };
  }, [shouldTrap, focusScope, focusedFrom]);

  return setFocusScope;
};
