import React from "react";

type Props = {
  color: string;
  diameter: number;
  className?: string;
  style?: React.CSSProperties;
};

const Circle: React.FC<Props> = ({style = {}, color, diameter, className = ""}) => (
  <svg height={diameter} width={diameter} className={className} style={style} role="none">
    <circle cx="50%" cy="50%" r="45%" strokeWidth="3" fill={color} />
  </svg>
);

export default Circle;
