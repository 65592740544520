import {V5_ROOT} from "src/useV5ImgSrc";

export type ImageExtension = "jpg" | "jpeg" | "png" | "gif" | "webp" | "svg";

const nextImageSource = (src: string, extension: ImageExtension = "jpg"): string => {
  const path = src[0] === "/" ? src : `/${src}`;

  return `${V5_ROOT}${path}.${extension}`;
};

export default nextImageSource;
