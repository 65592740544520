import {colors} from "@c10h/colors";
import React from "react";

/**
 * This is used to fine tune the animation.
 * Because the animation can not be based off of percentage alone, static values must be used.
 * Offset category should roughly correspond to the width of the glimmer element.
 * When in doubt, go up a category.
 */
export enum GlimmerOffset {
  XXS = "100px",
  XS = "200px",
  SM = "400px",
  MD = "700px",
  LG = "1200px",
  XL = "1900px",
}

type Props = {
  height?: number | string;
  width?: number | string;
  offset?: GlimmerOffset;
  backgroundColor?: string;
  waveColor?: string;
  style?: React.CSSProperties;
};

const Glimmer = ({
  height = "100%",
  width = "100%",
  offset = GlimmerOffset.SM,
  backgroundColor = colors.gray100,
  waveColor = colors.gray50,
  style = {},
}: Props): React.ReactElement => (
  <div
    style={{
      height,
      width,
      ...style,
      "--glimmer-offset": offset,
      "--shimmer-bg": backgroundColor,
      "--shimmer-wave": waveColor,
    }}
    className="shimmer br1 minh4"
  />
);

export default React.memo(Glimmer);
