import {useCallback, useState} from "react";

/**
 * Creates a small cache for a frequently changing query.
 * Does not persist between page changes, refreshes, or between various calls to `useCache`.
 * Only works for a single instance of calling `useCache`.
 */
export const useCache = <T>() => {
  const [cache, setCache] = useState<Record<string | number, T>>({});

  const getCachedValue = useCallback(
    (key: string | number) => ({
      isHit: key in cache,
      value: cache[key],
    }),
    [cache],
  );

  const updateCache = useCallback((key: string | number, value: T) => {
    setCache(state => ({
      ...state,
      [key]: value,
    }));
  }, []);

  return {getCachedValue, updateCache};
};
